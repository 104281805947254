const row = (r, s) => Array.from(s).slice(r * 9, r * 9 + 9);

const column = (c, s) => Array.from(s).filter((_, i) => (i - c) % 9 === 0);

const block = (x, y, s) => Array.from(s).slice(y * 9 * 3, (y + 1) * 9 * 3).filter((_, i) => {
  const mod = (i - x * 3) % 9;
  return -1 < mod && mod < 3;
});




const check = size => solution => {
  // check only valid solutions (singular numbers)
  if (solution.some(e => e.length !== 1)) return false;
  const s = solution.map(e => e[0]); // extract the number

  const rootSize = Math.sqrt(size);
  return Array.from(new Array(9), (_, i) => i).every(i => {
    const x = i % rootSize;
    const y = Math.floor(i / rootSize);
    const r = row(i, s);
    const c = column(i, s);
    const b = block(x, y, s);

    return (
      unique(r).length === size &&
      unique(c).length === size &&
      unique(b).length === size
    );
  });
}

const check9 = check(9);

const unique = a => a.reduce((acc, e) => {
  if (acc.includes(e)) return acc;
  acc.push(e);
  return acc;
}, []);

export default {
  row,
  column,
  block,
  check9
}
