import React, { useState } from 'react';
import Cell from './Cell';
import Sensor from './Sensor';
import Controls from './Controls';
import Utilities from './Utilities';
import Grid from './Grid';
import Solved from './Solved';
import './Sudoku.css';
import Help from './Help';
import sudokus from './sudokus.js';
// import config from './config';
import toggleFullScreen from './toggleFullScreen';
import constraints from './constraints';
import query from './query';

function Sudoku({
  gridBackground = "#fff",
  cellHoverColor = "#999"
}) {

  const q = query.params();

  // some random examples
  const next = current => {
    let n
    do {
      n = Math.floor(Math.random() * sudokus.length)
    } while (n === current && sudokus.length > 1);
    return n;
  }
  const [ currentSudoku, setCurrentSudoku ] = useState(next(null));


  const numbers = s => Array.from(s).map(n => Number.parseInt(n, 10) || null);
  const getSudoku = n => numbers(sudokus[n]);
  const convertSudoku = sd => sd.map((s, i) => s ? [s] : []);
  const [ sudoku, setSudoku ] = useState((q.sudoku && numbers(q.sudoku)) || getSudoku(currentSudoku));
  const [ solution, setSolution ] = useState(convertSudoku(sudoku));
  const [ confirmed, setConfirmed ] = useState(sudoku);
  const [ activeCell, setActiveCell ] = useState(null);
  const [ solved, setSolved ] = useState(false);
  const [ help, setHelp ] = useState(false);

  const updateSolution = (solution, i = null, n = null) => {
    setSolution(solution);
    if (i === null) return;
    const newConfirmed = Array.from(confirmed);
    newConfirmed[i] = confirmed[i] || n ? null : solution[i].length ? solution[i] : null;
    setConfirmed(newConfirmed);
    const solved = constraints.check9(solution.map((e, i) => newConfirmed[i] ? e : []));
    setSolved(solved);
    if (solved) {
      setActiveCell(null);
    }
  }

  const newGame = () => {
    const n = next(currentSudoku);
    setCurrentSudoku(n);
    const newSudoku = getSudoku(n); // updates do not happen sinchronously, so use the same to fill both
    setSudoku(newSudoku);
    updateSolution(convertSudoku(newSudoku));
    setConfirmed(newSudoku);
    setActiveCell(null);
    setSolved(false);
  }
  // replace this with API

  // time
  const [ startTime /*, setStartTime */ ] = useState(new Date());
  const [ time /*,setTime*/ ] = useState(new Date());
  // window.setInterval(() => {
  //   const d  = new Date();
  //   if (d - time < 10000) return;
  //   else {
  //     setTime(d);
  //   }
  // }, 50);

  const cells = solution.map((values, i) => {

    const x = i % 9;
    const y = Math.floor(i / 9);
    const activeValues = (activeCell !== null && solution[activeCell]) || [];

    const relevant = activeValues.some(val => values.includes(val));

    // check for errors
    const solutionWithout = Array.from(solution);
    solutionWithout[i] = [];

    const [
      row,
      rowConfirmed
    ] = [
      constraints.row(y, solutionWithout),
      constraints.row(y, confirmed)
    ];
    const [
      column,
      columnConfirmed
    ] = [
      constraints.column(x, solutionWithout),
      constraints.column(x, confirmed)
    ];
    const [
      block,
      blockConfirmed
    ] = [
      constraints.block(Math.floor(x / 3), Math.floor(y / 3), solutionWithout),
      constraints.block(Math.floor(x / 3), Math.floor(y / 3), confirmed)
    ];

    const onlySolutions = [].concat(row, column, block);
    const solutionsWithConfirmed = [].concat(rowConfirmed, columnConfirmed, blockConfirmed).map((e, i) => ({
      value: onlySolutions[i].length ? onlySolutions[i][0] : null,
      confirmed: !!e
    }));

    const faults = solutionsWithConfirmed.reduce((acc, e) => {
      if (!e.confirmed) return acc;
      if (!e.value) return acc;
      if (acc.includes(e.value)) return acc;
      if (values.includes(e.value)) acc.push(e.value);
      return acc;
    }, []);

    const faulty = confirmed[i] && (
      row.some(e => e.some(e => values.includes(e))) ||
      column.some(e => e.some(e => values.includes(e))) ||
      block.some(e => e.some(e => values.includes(e)))
    );

    return <Cell
      key={i}
      x={x*100+10}
      y={y*100+10}
      value={sudoku[i]}
      confirmed={confirmed[i]}
      potentialValues={solution[i]}
      faulty={faulty}
      faults={faults}
      relevant={relevant}
      active={i === activeCell}
    />
  });

  const sensors = Array.from(sudoku).map((s, i) => {
    const x = i % 9;
    const y = Math.floor(i / 9);
    return <Sensor
      key={i}
      x={x*100+10}
      y={y*100+10}
      onClick={() => setActiveCell(i !== activeCell ? i : null)}
      cornerRadius="0"
    />
  });

  let lastTime = new Date();
  const change = n => {
    if (activeCell === null || sudoku[activeCell]) return;

    // double click
    let doubleClick = false;
    const now = new Date();
    const diff = now - lastTime;
    if (diff < 300) {
      doubleClick = true;
    }
    lastTime = now;

    const newSolution = Array.from(solution);
    let potentialValues = Array.from(newSolution[activeCell]);
    if (doubleClick) {
      potentialValues = [n];
    } else if (potentialValues.includes(n)) {
     potentialValues.splice(potentialValues.indexOf(n),1); // delete
    } else {
      potentialValues.push(n); // add
    }
    newSolution[activeCell] = potentialValues;
    updateSolution(newSolution, activeCell, n);
    if (doubleClick) toggleConfirm(null, n); // lol. "lazy" programming
  };

  const erase = () => {
    if (activeCell === null || sudoku[activeCell]) return;
    const newSolution = Array.from(solution);
    newSolution[activeCell] = [];
    updateSolution(newSolution, activeCell);
  }

  const toggleConfirm = (exception, value = null) => {
    if (activeCell === null || !(value || solution[activeCell].length)) return;
    const newSolution = Array.from(solution);
    newSolution[activeCell] = [value || newSolution[activeCell][newSolution[activeCell].length-1]];
    updateSolution(newSolution, activeCell);
  }

  const toggleHelp = (exception, value = null) => {
    setHelp(!help);
  }

  return (
    <React.Fragment>
      <div className="Sudoku">
        <header>
          <h1>SudokuHQ.com</h1>
          <span className="slogan">
            &bull; Play and Print sudokus!<br/>
            &bull; &quot;Mobile-friendly&quot; &mdash; Everyone
          </span>
        </header>
        <div className="Sudoku-game">
          <div className="Sudoku-game-grid">
            <svg version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 920 920">
              <rect x="10" y="10" width="900" height="900" fill={gridBackground} stroke="none" />
              <Grid />
              {cells}
              <Solved visible={solved} />
              {sensors}
            </svg>
          </div>
          <div className="Sudoku-game-controls">
            <div className="Sudoku-game-controls-control">
              <Controls onClick={change} values={solution[activeCell]} confirmed={confirmed[activeCell]}/>
            </div>
            <div className="Sudoku-game-controls-control">
              <Utilities
                erase={erase}
                time={time - startTime}
                fullScreen={toggleFullScreen}
                newGame={newGame}
                confirm={toggleConfirm}
                help={toggleHelp}
              />
            </div>
          </div>
        </div>
        { help && <Help close={toggleHelp} /> }
      </div>
    </React.Fragment>
  );
}




export default Sudoku;
