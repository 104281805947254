
const params = () => {
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = s => decodeURIComponent(s.replace(/\+/g, ' '));
  const query  = window.location.search.substring(1);

  const o = {};
  let match;
  while (
    (match = search.exec(query))
  ) o[decode(match[1])] = decode(match[2]);
  return o;
}

export default {
  params
}