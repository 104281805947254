import React from 'react';
// import Sensor from './Sensor'

function Help({
  close
}) {

  return (
    <div class="Help">
      <button class="close" onClick={close}>🆇</button>
      <h3>How to play:</h3>
      <ul>
        <li>Select a cell in the grid, then select one or more possible numbers.</li>
        <li>When you think that the number is correct, click <code>Confirm</code>.</li>
        <li>If the cell has multiple hint numbers, the last one entered will be confirmed.</li>
        <li>To start the cell over, use <code>Erase</code>.</li>
        <li>You can also double-tap/double-click on a digit to confirm it.</li>
        <li className="strike">Turn off hints for a challenge.</li>
        <li>Use <code>Full screen</code> for distraction-free mode.</li>
      </ul>
    </div>
  );
}

export default Help;
