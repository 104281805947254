import React from 'react'

function Text({
  x = 0,
  y = 0,
  size,
  value,
  color = "#222",
  style = {}
}) {
  return (
    <text
      x={x}
      y={y}
      style={{
        ...style,
        // pointerEvents: "none",
        fontSize: size
      }}
      textAnchor="middle"
      dominantBaseline="central"
      fill={color}
    >
      {value}
    </text>
  );
}

export default Text;