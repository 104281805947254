import React from 'react';
import './Solved.css';

function Solved ({
  visible = false
}) {
  return (
    <g transform='translate(10 10)' style={{
      display: visible ? 'block' : 'none'
    }}>
      <g transform='translate(450 450)'>
        <g className='Solved' >
          <path d={`
            m -250 -250
            h 200
            v 100
            h 100
            v -100
            h 200
            v 100
            h 100
            v 200
            h -100
            v 100
            h -100
            v 100
            h -100
            v 100
            h -100
            v -100
            h -100
            v -100
            h -100
            v -100
            h -100
            v -200
            h 100
            z
          `} fill='rgba(255,0,0,1)' />
          <path d={`
            m -250 -50
            v -100
            h 100
            z
          `} fill='rgba(255,255,255,.5)' />
          <path d={`
            m 50 -50
            v -100
            h 100
            z
          `} fill='rgba(255,255,255,.5)' />
          <path d={`
            m 350 -50
            v 100
            h -100
            v 100
            h -100
            v 100
            h -100
            v 100
            h -100
            z
          `} fill='rgba(0,0,0,.5)' />
        </g>
      </g>
    </g>
  );

}

export default Solved;
