import React from 'react';
import Sensor from './Sensor'

function UtilitiesButton({
  x = 0,
  y = 0,
  width = 1,
  height = 1,
  icon = '',
  label = '',
  onClick = null
}) {

  const fX = (450 - 60) / 4; // size without spaces
  const fY = (450 - 60) / 4; // size without spaces
  const xA = 10 + x * fX + (x * 20);
  const yA = 10 + y * fY + (y * 20);
  const wA = (width - 1) * (fX+20) + fX;
  const hA = (height - 1) * (fY+20) + fY;

  return (
    <g>
      <rect
        x={xA}
        y={yA}
        width={wA}
        height={hA}
        fill={onClick ? "#eee" : "#999"}
        stroke="none"
        // strokeWidth="5"
        rx="5"
      />
      <g transform={`translate(${xA+wA/2} ${yA+hA/2})`}>
        <text
          style={{
            pointerEvents: 'none',
            fontSize: fX/3
          }}
          textAnchor="middle"
          dominantBaseline="central"
          fill="#555"
        >{onClick ? [icon, icon&&label&&' ', label] : ''}</text>

      </g>
      <Sensor x={xA} y={yA} width={wA} height={hA} onClick={onClick} />
    </g>
  );
}

export default UtilitiesButton;
