export default [ // easy -> hard (?)
  //"362819745784653912915742863279584136641327_98538961274853196427126478359497235681"
  //*
  "_327__14_6__3__5_7______2__2_3_4_71__7___2_6___6__13_2_2__176_58_76__421_642_5_7_",
  "_58_73___2__4__3_7__9____58_4_3217_5__2_9__31183567942_2574_8__8__6___793_718_2__",
  "7__84__3__9_7__6___68392_751_3__8____791___636_5_738_22_75_43_693_2175_45_4_3_721",
  "2_56_9_7841687_259879_54__6748396521_91___68__2_5__94__5213_764__7__58____47_219_",
  "914__85__8269___31_7____4981_2_74__3__5_2__4_34__5918___856127__694873__7__3__86_",
  "4_5___21_26__8_935_3___1__4_486731_9_7_____43_1_4__7_6_9483__723___97_5_8___463_1",
  "9_6453271_478_15_6_1_26_4__65_9_87_342_175_9__9832_1__579632814_64____521_2584_67",
  "_91__6758_2__7_3__6_74_5__1__3___51_176__3__92598__6_47_4_51_8__1_7284_3_82_94175",
  "56289_47__37__286_____5729_4_69_8____734156_9_9__7618_____6_742624__9__87___8_93_",
  "1__9_4857__462_1__98_5__4_2_2_3157__7____63__341___5__217___94__3___921__59__2673",
  "41_53_8_23_2__1___6_5_7_3_18469_2_37_2_45___69513_74_81______83237_8___55_86_3___",
  "_97__6_31____7____8_____47664_3_721__524___6__18__5__3___76915__7__346_2964___3_7",
  "_1_46__2774592_38628_753941_32____9__985___64_67_9_1__354679_1262____4_9_7921__35",
  "4_9_728537__4__2___2___5479___1__387_7_35__422389476_5_93564____6__91_34___7_319_",
  "__1_3____6__4_______8_1_5_4_6____41_59_86__37_73_42____15_7____3__5____17491__38_",
  "967358214__512_693312649857786_13_29__9865_41_54__236__7158_932__37_1486_9_234___",
  "63_2_47___51__3_____91__6_32______6_94_712___87_5_61_23__62__4556438_2__1___5937_",
  "4__6___7_872_4__91_1682_35_2__1_39466___8___7_4395_1__38__14_6___7_384___2_____83",
  "____87_49_____2_31__359___68_1__94_3__4_5_9__9____6__87429__8151_87____469__14__7",
  "7__8_3_2_89142735_362__54_89____15__513974_8248725619_1__7_28696_8519234_496_87__",
  "261839__758_264_13__37_56_8854_21___19__87432__249__5__39652_8_6_8943275425_7836_",
  "35__17_4______691__12_9_85_17__423__2_8__14_5_645837215_6_28___8_1__9____431___98",
  "2__36___54___25____5_17_4___8____374__471__58_79483_627___9_5__14__372___3____6__",
  "_94685_31_67_41__9_5897__64_367984_2_124_6_9_489__26_792_56417867182_3458_5137926",
  "_21_7__6_6__13____85____7_2__5_87936_4_65328_3_____54_5____6__4____2_1_519_______",
  "____718__32_89_5_78_1______2___874937_453________19_2513_9_6_5__6215____9_8_2314_",
  "853_76___69_483_____19256835_921__343__5______173__8597______211_5642_9__247____8",
  "_5_1__6__6_4723985_2___8_142___7__388392_4__75473_6_921_2___85_3758_1___48__9_3_1",
  "9_______66_71__8__2____6_1__2_9__78_76_4825_14_357_6928_135_2_75726189_33___9_1_8",
  "7_2_1936_419_3582__687_29_56_5_7_183_9_38_5_28_____7_697625_431_3_197_58___463279",
  "87_62193412_9_75683__4_5172_48176_____954_281531___6_7_1_7_482___78__493482359716",
  "7___1523953__421_61__3_6__4946_538272____8_6337_62_95_8__53_____15_6___86___897_5",
  "35_9_68__618_437__749_823_5_94_58136_7623_589_3_6_1_474278__65358_46_92_9__32_4_8",
  "8_9____4_7___38_9_361_7_8_5___2_341__43_57_829_26_4573__87_5_6_69_________5______",
  "_295_387_57_91432613672_9__96__4_1__3846512_72_13_9468713_9658__9__3___2642185739",
  "_6__1_38212349__7575_23_91__72_5__616_4_27__393_64__2____98__562975___4_58__74_39",
  "_6324___8____58_344_5___291296_8341__489213___5_7648298___76_52_29415_835378___46",
  "4_39___8___73_6492_92____3__4______3726_3__1_31_27_6_9_6479_32_2_1____479_542_168",
  "6297513_8153__92_68__2369_598___26347_631_59_5326_4____9514_8_74_89_71_33_786_429",
  "1745862393521_9_6_69__2714_981_65_2__43_____6__5_938____7__49__526971_83___2_8_5_",
  "__58_27_484167__2_7_2594_3_52__8__9_1_82_9___6___3__8_2__34___5__31__64_4879_6___",
  "5____62_36_1_______92_31__6_134896___652__3_9_4_56_82_32_6_417___43_29_89__178_3_",
  "9_62_571872__143655138_6______723854____5_69_354_9__71169_42__78_51__4__4_258_136",
  "9__1285_6__13_____2659_7___3497_51__5_2_3____786412953_______2_69__8471_12_5__6_9",
  "_79__852385_79214____4358___1_5742__296_817_454__293_16_184_93__84_536_29___1_4_8",
  "6_3__9__1___2_49____253___7_51_2_69__96_53_2_2__89_1351_5__2___42_3___7__6_____1_",
  "748591_62_62_84___359__68___158___4643615_7892874_91_5_9162_47387_9_562_6__71_95_",
  "91_837_45_7_1268_328__49167__27___1_8913_24__34__61_8__38_957_11__673___7_9___63_",
  "___12____4_1_983_6_39674182978_6____15238__6734_71_5___65_4___38___5674__9483_625",
  "_25____74____42_6394__7_2_84_23_1__9___4_8_17_3__9__2_____65_31_1_____42853_1____",
  "___2_391_8216943755__817__215__49_8_9__1___4_24__8519649___6_31_8_93_75_31_478__9",
  "_41_8_9635__74_8___836915_4__59_8_36_321_4__916___724895847_31___7_32_9___4_1___7",
  "58__3_627___5__93_1_3____5_4___7_196_7__14___9___6__74_5_4_38_223___6__9_49______",
  "___2____1_927_____4_6____3_32__45__9__13____6689_27_5__3451_9_8_____2__72_____6__",
  "73652841_985__3627241_9635__57_8_1__618_459__329_7_845_74_39___1628_4_9_5_3_67_84",
  "43_5____1_1__4_2__5_78__64__56_24___1__6___2__2__8_764_61_574_______159____9_81__",
  "8__7__942__7_285___3_1_9_6892_417_5_7___9____164__38795_____19__4_9__3853795814_6",
  "2_74___38369_78_1_85461__9714_7_5__9685_9_473_7_8641_572_94_35659138674_43_52_9__",
  "1____97_5__9_3216_6_475_2932__6_481_896_154377___8___2__2_96_7835__2_9____7__8321",
  "____1_38_62_3__7_918___9__293742185_2_8__39___4___6_3_39_1__42_4_2_38_75875_6419_",
  "_5_1___2616_2____8____7_14___6____5_51___36_22____1_87_4_3_857__895____3__59_7864",
  "_8___1764641_78_95__9_452_1_2_43_5___368___27__4___8_6_18__26____3_____2___7_31__",
  "578_4_3___12_59784_93__8__1____1_4__235__46__14__8__7_8__4_7_6__2_86594_364921_57",
  "_27__3__1_18_9_5___95___478_7___4_2_83_6____4_6__598139____6_____2345_6__5_928__7",
  "276351498_5386_2_1_9__743__38__2__1__241_6__36_5_3_8_9532_1_94_7___431_2__869__3_",
  "8237941_5_1_6__4286_5281_37_8_52__943_784_25_45_13_786578_12649_314_587_2649785_3",
  "1932_875_7_593621_624_17_8_8_637254__398__6__572_9__3836__294_52__463___941_85_6_",
  "_5__26______84_1____217935_______81_5_6___47____7689__4_________1528___4__9__3___",
  "3_4_6257956791_3_______3__6___3__94897____1___3____62_7_3_8__5___27___9_8_14_5_6_",
  "_691_758_5_78439__8416952__712438695_8_2_93___3_5___27__3_12___2_6_8__59_98756432",
  "_8_26__1_14____36839641____9___3______3_9__45__184597_268___59441_5_623_73_9_418_",
  "__2_7158_1_______75678____2___________82_56_9_94187___2____8_3_4397__85_851_____6",
  "54__73___83_5__4679768_152_7_39_2_____438___569_41_2_836_1___5__1_2_5_7925_73_1__",
  "64123597__8__461_33__81_2____7_9_83_83_472___9__583_27_69_2478_478_5__622537_8491",
  "82_4_5_17___2_8___5__71_8____2__7_9_7_35_42_6_8_3_1_54_71852___2__1___73_45973___",
  "58_964_____1_58_4646_172_85_2___5___31628__7__5__13_681_5_368_76_78_1__98_2597631",
  "6_7_9381_812_65_49_941_87654_95_12362_68__57_57123__849653____81__98___7_28_54__3",
  "2__8___43___7126_5586349712__8_9_5_47____4___46__8_391_9__2183787_6____9_21___4__",
  "_16_24_7_5_3_6___2724_3_6_______893_3__2__5__4__91__2___934__65__56__7__6__7_51_9",
  "___547_3______2_9_6__3____7_63_9____795_3_8_1412____5__74__3_86389_26__52___71__3",
  "__4871_5__59_3_748__6__4_21__5_____66___1__92__1765834_____64878_2___965____9__1_",
  "__6_3__81_52__94361_4_689___43____92_1__74_6_6879_2_4___1647__5__8_9__1__6__21_7_",
  "_64__1927_7_964_589583_71_____14___91__8____2_4_2735_65_271__4____69__85687_352_1",
  "2__73495_9___6__177_89512634_61_5__213__7_5895_98_364131_6_982_89_51__76625387_9_",
  "__72__6_51_34__2__629___841_3265__89_1_3_847_4_8__95_3_761_395___4_6_7125__87___4",
  "_689_____7___28_65___6___9243_2__578__7___62___97_54____4__23__3825___1_91_8732__",
  "7_4_1_8_58__5____1_16378______43__1___3__598__6_2__57__97_43__26__79__48____52_97",
  "7__9_423__2_5__9___5____846_7_3_16__31__9657__9___8__38_5_______6___5___1_9___45_",
  "__6_125_9__9___2_65__69387__2_1______31927_58_5_348_6_26_5__91_1_5__9_84_98_61_25",
  "___2___947_43_6__5_5_1_7236__2_6_3574___3_6_8_8_72_____3____96169_51347224_67___3",
  "____3__6_3_85497_274_2689__1_7__624__8_1275_652___3__78_2__46916__9______1_682475",
  "94738_5__815_4_76326__5_8947_64_198_48_67_2___5__9__7617___4_59__47_3___638_2_1_7",
  "2_78__5_435_6_2_711_69__832_7__9__2_43258__97_6__3__855_____719__43______89___2__",
  "_721__6_84_6_3_____1__76_3_7__325____2___17_416948_2____49_3_17837_12946_91_4____",
  "93_2__61_471__5_39__21_94__5__38_7_26__4125932_____8611_69_83___94_2__8_358___92_",
  "_25__6___1_____6__96_81_7_5_8___1__94_7_59___6594___7___1738____9_____3_3____58__",
  "64_9___18751_382_992_5_4_37_8__964714_728_356_1_4____2_7_16_8__8627_51_313_8_____",
  "_____2763___8_61_96__14___55162____7237__54_694___751_19532867_82___935_76_45_298",
  "__8__3__21_6_8_47__9______894_8___23___63___736___7__167_3__215_____67_4_8__15_3_",
  "___324615___8_5_93_36__724895843_1___4__5_839_617__45__9367_52_6152_398____5_8_6_",
  "792_6_5_3____5_7_668_13_4__2_78___61_19_26_____49___5________3_3_1_8__9_97______5",
  "2___854___76_2_1__34_16729_18275__49_5489137__39246_15__867__3452_4__7__4_753_9_1",
  "_31_6298482934516746__982359_5624_711725_96_8346817592653481729798256413214973856",
  "61_3____874__9635123__1_7_65_3924__787_63_4____487_6_5___563___4_718_56_3_6_498_2",
  "7_58_4632_3_26_5___263597819716__45356419327_28347516_85_73194661___83_734__26815",
  "_9__31__2_2_4_7_8__1_6___9_28__5_3_61____2_4___91_625_438_1__7__62___93__7_5___28",
  "542__8196__652___3__3469_57___716__848593__71761_549__6__283715237_9_86__5864732_",
  "891_46_23_2739864_4365218__6_927__85378___2_4_4_683_97152967____83412____6483591_",
  "__4367__11_24__687_75____34__862_59________18_13584__23298_5_7_5_17___4__87196_25",
  "82__1___37___3584_35___4_12_6__43__7__157_36__376____86___2__81_4___79_6_82___7_5",
  "___5___8934__8__67982__453159___1___7_8_6219_____5___841__9___68____6___6_9__581_",
  "_796__832__43__6_162__18__79624__3_55_12________15326__46__15_3718__492___5__6174",
  "8_3__7___96_28_7_4_5_9_6_3___61_52482__6_45_11457_8_63537______68237941_4____238_",
  "4312__7_58__34_269____8_1__1_7____5_______9__546__9__86_41_____2__638_4__8_95_612",
  "2_756981__________8____4__5_89__21_667_3814____27_65835_36___497_1_35628___2473__",
  "8____1_676278_5_9_9__6_7385_7___6512__2__49_63615924__19645_7_3__576984_74_2___5_",
  "__1_946754_96__1_2_7__52_4313_9___5_____178__98___5__1__27_6314__4_3_72___34_95_8",
  "47892_1651__87_29_932615_7_8_93615_7523_4__167__2598346_149__5225413678__9_582_4_",
  "53__746_9__8__274141_89623_263____58____389___892__4__871_63__292_5813763__9__8__",
  "2_1359_76_63_____9_546718__6_8435________2_84___968_53_4_283965896_4__2_325196748"
  // */
];
