import React from 'react';
import Sensor from './Sensor'

function Controls({
  onClick,
  values = [],
  confirmed = null,
  onColor = '#f9f',
  offColor = '#fff'
}) {

  const controls = Array.from(new Array(9), (_, i) => i + 1).map((n, i) => {
    const a = 150;
    const x = i % 3;
    const y = Math.floor(i / 3);

    const f = (450 - 40) / 3; // size without spaces
    const xA = 10 + x * f + (x * 20);
    const yA = 10 + y * f + (y * 20);

    const fill = values.includes(n) ? onColor : offColor;

    return (
      <g key={i}>
        <rect
          x={xA}
          y={yA}
          width={f}
          height={f}
          fill={fill}
          stroke="none"
          // strokeWidth="5"
          rx="5"
        />
        <text
          x={xA+f/2}
          y={yA+f/2}
          style={{
            pointerEvents: 'none',
            fontSize: a/2
          }}
          textAnchor="middle"
          dominantBaseline="central"
        >
          {n}
        </text>
        <Sensor x={xA} y={yA} width={f} height={f} onClick={() => onClick(n)} />
      </g>
    );
  });


  return (
    <svg version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 470">
      {controls}
    </svg>
  );
}

export default Controls;
