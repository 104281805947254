import React from 'react';
import Text from './Text';

function Cell({
  x,
  y,
  value,
  confirmed = null,
  potentialValues = [],
  // defaults
  height = 100,
  width = 100,
  active = false,
  activeBgColor = 'rgba(255,255,0,0.2)',
  relevant = false,
  relevantBgColor = 'rgba(0,0,255,0.1)',
  faulty = null,
  faults = [],
  faultyColor = 'rgba(255,0,0,0.50)',
}) {

  const color = value?'#222':faulty?'#f00':confirmed?'#00d':'#000';
  const numbers = value || confirmed
    ? <Text x={width/2} y={height/2} size={height/1.5} value={value||confirmed} color={color} />
    : Array.from(new Array(9), (_, i) => i + 1)
        .map((n, i) => {
          const xP = i % 3;
          const yP = Math.floor(i / 3);


          const padX = width/6;
          const stepX = width/3;
          const padY = width/5;
          const stepY = width*1.5/5;

          return potentialValues.includes(n)
            ? (
              <Text
                key={i}
                x={padX + xP * stepX}
                y={padY + yP * stepY}
                size={height/3}
                value={n}
                textAnchor="middle"
                dominantBaseline="central"
                color={color}
                style={{
                  pointerEvents: 'none'
                  // stroke: '#fff',
                  // strokeWidth: '1pt'
                }}
              />
            )
            : null
        });

  const fill = relevant?relevantBgColor:'none';

  const error = faulty
    ? <g>
        <line x2={width} y2={height} stroke={faultyColor} strokeWidth="5" />
        <line x1={width} y2={height} stroke={faultyColor} strokeWidth="5" />
      </g>
    : faults.map((n, i) => {
        const x = (n - 1) % 3;
        const y = Math.floor((n - 1) / 3);
        const step = width / 3;
        const x1 = x * step;
        const x2 = x1 + step;
        const y1 = y * step;
        const y2 = y1 + step;
        return (
          <g key={i}>
            <line x1={x1} y1={y1} x2={x2} y2={y2} stroke={faultyColor} strokeWidth="5" />
            <line x1={x1} y1={y2} x2={x2} y2={y1} stroke={faultyColor} strokeWidth="5" />
          </g>
        );
      });

  return (
    <g transform={`translate(${x} ${y})`}>
      <rect
        width={width}
        height={height}
        fill={fill}
        stroke='#f0f'
        strokeWidth={active?8:0}
      />
      {error}
      {numbers}
    </g>
  );
}

export default Cell;
