import React from 'react';
import UtilitiesButton from './UtilitiesButton'

function Utilities({
  time,
  playPause = null,
  erase = null,
  hint = null,
  help = null,
  print = null,
  fullScreen = null,
  swapH = null,
  swapV = null,
  newGame = null,
  confirm = null
}) {

  // const clock = new Date(time);
  // const t = clock.toISOString().slice(11, 19);

  return (
    <svg version="1.1" baseProfile="full" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 470">
      <UtilitiesButton x="0" y="0" width="2" height="1" icon="☑️" label="Confirm" onClick={confirm} />
      <UtilitiesButton x="2" y="0" width="2" height="1" icon="❌" label="Erase" onClick={erase} />

      <UtilitiesButton x="0" y="1" width="2" height="1" icon="🔎" label="Hint" onClick={hint} />
      <UtilitiesButton x="2" y="1" width="2" height="1" icon="📖" label="Help" onClick={help} />

      <UtilitiesButton x="0" y="2" width="1" height="1" icon="🖨️" onClick={print} />
      <UtilitiesButton x="1" y="2" width="1" height="1" icon="🖥️" onClick={fullScreen}/>

      <UtilitiesButton x="0" y="3" width="1" height="1" icon="↔️" onClick={swapH} />
      <UtilitiesButton x="1" y="3" width="1" height="1" icon="↕️" onClick={swapV} />
      <UtilitiesButton x="2" y="3" width="2" height="1" label="New game" onClick={newGame} />
    </svg>
  );
}

export default Utilities;
