import React, { useState } from 'react';

function Sensor({
  x,
  y,
  // defaults
  width = 100,
  height = 100,
  hoverStroke = 'rgba(0,0,0,1)',
  onClick = null,
  cornerRadius = 5
}) {

  const [ id ] = useState('clip' + Number.parseInt(Math.random() * 1000000, 10));

  const [ hover /*, setHover*/ ] = useState(false);
  // const [ hoverCell, setHoverCell ] = useState(null);

  const onMouseOver= e => {
    // console.log(e.type);
    // setHover(true);
  };
  const onMouseOut= e => {
    // console.log(e.type);
    // setHover(false);
  };

  return (
    <g transform={`translate(${x} ${y})`}>
      <defs>
        <clipPath id={id}>
          <rect
            width={width}
            height={height}
            fill="rgba(0,100,0,1)"
            rx={cornerRadius}
          />
        </clipPath>
      </defs>
      <rect
        width={width}
        height={height}
        fill="rgba(0,0,0,0)"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        stroke={hover?hoverStroke:'none'}
        strokeWidth="10"
        onClick={onClick}
        rx={cornerRadius}
        style={{
          cursor: 'pointer'
        }}
      />
    </g>
  );
}

export default Sensor;
