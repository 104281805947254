import React from 'react'

function Grid({
  strokeColor = "#555",
  thinLineWeight = "1",
  thickLineWeight = "5"
}){
  const sudokuLine = Array.from(new Array(8), (_, i) => i + 1);
  const hLines = sudokuLine
    .map(i => <line key={i} x1={i*100+10} y1="10" x2={i*100+10} y2="910" stroke={strokeColor} strokeWidth={i%3?thinLineWeight:thickLineWeight} />);
  const vLines = sudokuLine
    .map(i => <line key={i} x1="10" y1={i*100+10} x2="910" y2={i*100+10} stroke={strokeColor} strokeWidth={i%3?thinLineWeight:thickLineWeight} />);

  return (
    <g>
      {hLines}
      {vLines}
    </g>
  );



}

export default Grid;